














import { defineComponent } from '@vue/composition-api'
import router from '@/router'

export default defineComponent({
  name: 'DeskMission',
  setup() {
    const handleRedirect = () => {
      router.push({
        path: '/introduct'
      })
    }
    return {
      handleRedirect
    }
  }
})
